import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Es reconeix fàcilment per la seva olor a col bollida. Aquest bolet posseeix un capell d’1,5 a 4 cm de diàmetre, pla amb el marge estriat en temps humit. El color és variable, de terrós rogenc amb el marge més clar a crema rogenc als exemplars vells. Les làmines amb nombroses lamel·les són blanquinoses al principi per passar a rogenques. El peu, del mateix color que el capell a la part superior i negrós a la resta, és cartilaginós, solcat i aplanat. Les espores són blanques en massa, el·líptiques, de 6-8 x 3-3,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      